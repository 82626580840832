/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import styled from 'styled-components'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import axios from 'axios'
import { Link } from 'gatsby'
import SEO from '../components/Seo'
import device from '../utils/mq'
import Checkbox from '../components/Checkbox'
import { DownloadDescription } from '../components/blog/StyledBlogArticle'
import HiglightIcon from '../assets/Highlight-Effect.svg'
import BG from '../assets/blue-segment.svg'
import Elipse from '../assets/Ellipse.svg'
import TickIcon from '../assets/Tick.svg'

const webhookURL = `https://hooks.zapier.com/hooks/catch/3484805/38ifvw1/`
const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const Wrapper = styled.div`
  padding: 146px 15px 0;
  margin: 0 auto;
  background: linear-gradient(180deg, #fff 0%, #e3e6fa 100%);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  min-height: 100vh;
  padding-top: 156px;
  margin-top: -92px;
  overflow: hidden;
  @media ${device.laptop} {
    padding: 124px 40px 100px;
  }
  @media ${device.laptopL} {
    padding: 156px 40px 100px;
  }
`
const Hero = styled.div`
  margin: 0 auto;
  display: flex;
  max-width: 1100px;
  gap: 80px;
  flex-direction: column-reverse;
  align-items: center;
  @media ${device.laptop} {
    flex-direction: row;
    justify-content: space-between;
    gap: 160px;
  }
`
const Column = styled.div`
  flex: 1;
  text-align: center;
  @media ${device.laptop} {
    text-align: left;
  }
`
const ColumnTopText = styled.div`
  color: #7839cd;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.78px;
  text-transform: uppercase;
`
const ColumnnTitle = styled.div`
  color: #1b1b1b;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 115%;
  margin: 26px 0;
`
const TextAndIcon = styled.div`
  display: none;
  gap: 20px;
  margin-bottom: 30px;
  @media ${device.laptop} {
    display: flex;
  }
`
const TextAndIconMobile = styled.div`
  display: flex;
  gap: 4px;
  margin-bottom: 40px;
  flex-direction: column;
  align-items: center;
  @media ${device.laptop} {
    display: none;
  }
`
const TextIconContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`
const Icon = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
`
const TextTitle = styled.div`
  color: #061c3d;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 0px;
  @media ${device.laptop} {
    margin-bottom: 8px;
  }
`
const Text = styled.div`
  color: #42526b;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`
const FormWrapper = styled.div`
  background: none;
  @media ${device.laptop} {
    background: url(${BG}) no-repeat;
    background-position: top right;
  }
`
const BackgroundContainer = styled.div`
  display: flex;
  position: relative;
  img {
    position: absolute;
    top: -40px;
    right: -40px;
  }
  @media ${device.laptop} {
    display: none;
  }
`
const Form = styled.div`
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.1);
  padding: 26px 20px 56px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 0px;
  right: 0px;
  max-width: 513px;
  width: 100%;
  min-height: 600px;
  height: 100%;
  @media ${device.mobileL} {
    padding: 26px 50px 56px;
  }
  @media ${device.laptop} {
    top: 50px;
    right: 80px;
  }
`
const FormTitle = styled.div`
  color: #000;
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin: 12px 0 24px;
`
const FormInfo = styled.div`
  color: #191919;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`
const FormInputs = styled.div`
  margin: 40px 0 45px;
  width: 100%;
`
const TwoInputs = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 2px;
  margin-bottom: 22px;
`
const FormInput = styled.input`
  border-radius: 5px;
  height: 44px;
  padding: 9px 11px;
  width: 100%;
  border: ${(props) => (props.error ? '1px solid #E72E2D' : 'none')};
  background: ${(props) => (props.error ? '#FFDAD8' : '#f5f5f5')};
  font-size: 16px;
  font-weight: normal;
  line-height: normal;
  color: ${(props) => (props.error ? '#E72E2D' : '#000')};
  ${
    '' /* background-image: url(${(props) => props.img});
  background-repeat: no-repeat;
  background-position: right center;
  background-origin: content-box; */
  }
  &:focus {
    display: inline-block;
    color: #000;
  }
  &::placeholder {
    color: ${(props) => (props.error ? '#E72E2D' : '#8494b2')};
    line-height: normal;
    font-size: 16px;
  }
`
const FormButton = styled.button`
  color: #fff;
  text-align: center;
  background-color: #5a55ab;
  padding: 10px 30px;
  font-weight: 700;
  font-style: normal;
  font-size: 16px;
  letter-spacing: 0.25px;
  line-height: normal;
  border: 2px solid #5a55ab;
  border-radius: 100px;
  white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  position: relative;
  width: 100%;
  // box-shadow: 0 25px 30px -10px rgba(90, 85, 171, 0.6);
  transition: background-color 0.2s ease-out, border-color 0.2s ease-out;

  @media ${device.tablet} {
  }
  span {
    position: absolute;
    right: 22px;
  }

  &:hover {
    color: #5a55ab;
    background-color: #fff;
    border-color: #5a55ab;
    span svg path {
      fill: #5a55ab;
    }
  }
`
const FormCheckboxes = styled.div`
  margin-top: 34px;
  text-align: left;
  position: relative;
`
const InlineError = styled.p`
  font-size: 12px;
  margin: 4px 0 0 0;
  padding: 0 0 0 2px;
  background: none;
  font-weight: normal;
  color: #fb5854;
  position: absolute;
`
const ImageContainer = styled.div`
  padding: 50px 10px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-width: 364px;
    border-radius: 10px;
    box-shadow: 13px 12px 20px -13px rgba(0, 0, 0, 0.8);
    -webkit-box-shadow: 13px 12px 20px -13px rgba(0, 0, 0, 0.8);
    -moz-box-shadow: 13px 12px 20px -13px rgba(0, 0, 0, 0.8);
  }
`

const StyledTick = styled.div`
  display: grid;
  place-items: center;
  position: relative;
  margin-top: 24px;
`
const SuccessMessage = styled.div`
  text-align: center;
  h4 {
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 20px;
  }
  div {
    color: #191919;
    padding-bottom: 12px;
  }
`
const StyledTickIcon = () => (
  <StyledTick>
    <img src={Elipse} alt="elipse" />
    <img src={TickIcon} alt="tick" style={{ position: 'absolute' }} />
  </StyledTick>
)

const SuccessMessageWrapper = ({ title, link, name }) => (
  <SuccessMessage>
    <h4>Thank you, enjoy!</h4>
    <div>
      {capitalizeFirstLetter(name)} {`thank you for your interest in ${title}.`}
    </div>
    <div>
      You can always access{' '}
      <Link to={link} target="_blank" rel="noopener">
        this resource here
      </Link>
      .
    </div>
    <StyledTickIcon />
  </SuccessMessage>
)

const Download = ({ pageContext: { data } }) => {
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
  })
  const pageDescription = renderRichText(data.infoText)
  const [emailError, setEmailError] = useState('')
  const [firstNameError, setFirstNameError] = useState('')
  const [lastNameError, setLastNameError] = useState('')
  const [checkboxError, setCheckboxError] = useState('')
  const [success, setSuccess] = useState(false)
  const [inputOneChecked, setInputOneChecked] = useState(false)
  const [inputTwoChecked, setInputTwoChecked] = useState(false)
  const [successName, setSuccessName] = useState('')

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleCheckboxChange = (event, func) => {
    func(event.target.checked)
  }

  const onSubmit = async (e) => {
    let error = false

    if (formData.firstname === '') {
      e.preventDefault()
      setFirstNameError('Enter your first name')
      error = true
    }
    if (formData.lastname === '') {
      e.preventDefault()
      setLastNameError('Enter your last name')
      error = true
    }

    if (formData.email === '') {
      e.preventDefault()
      setEmailError('Please enter your work email')
      error = true
    } else if (!emailRegex.test(formData.email)) {
      e.preventDefault()
      setEmailError('Please enter a valid email')
      error = true
    }
    if (!inputOneChecked || !inputTwoChecked) {
      e.preventDefault()
      setCheckboxError('Checkbox selection is required')
      error = true
    }

    if (!error) {
      const fileUrl = data.downloadItem.file.url
      const link = document.createElement('a')
      link.href = fileUrl
      link.download = data.downloadItem.title // the download file name
      link.target = '_blank' // open in a new window
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      setSuccess(true)
      setSuccessName(formData.firstname)
      try {
        await axios.post(
          webhookURL,
          JSON.stringify({
            firstname: formData.firstname,
            lastName: formData.lastname,
            email: formData.email,
            itemName: data.slug,
            // time: new Date(),
            tag: 'Download',
          })
        )
      } catch (err) {
        console.error(err)
      }
      if (typeof window !== 'undefined') {
        // Parse the URL to get UTM parameters
        const urlParams = new URLSearchParams(window.location.search)
        const utmSource = urlParams.get('utm_source')
        const utmMedium = urlParams.get('utm_medium')
        const utmCampaign = urlParams.get('utm_campaign')

        window.gtag('event', 'download_button_click', {
          event_category: 'download',
          event_label: 'Download Button Click',
          utm_source: utmSource,
          utm_medium: utmMedium,
          utm_campaign: utmCampaign,
        })
      }
    }

    if (error) {
      return false
    }

    return true
  }

  return (
    <Wrapper>
      <SEO
        title={data.metadata.title}
        description={data.metadata.description}
      />
      <Hero>
        <Column>
          <ColumnTopText>Free download</ColumnTopText>
          {data.title && <ColumnnTitle>{data.title}</ColumnnTitle>}
          {pageDescription && (
            <DownloadDescription
              className="blog-post"
              itemScope
              itemType="http://schema.org/Article"
            >
              <section itemProp="articleBody">{pageDescription}</section>
            </DownloadDescription>
          )}
          {data.picture.file.url && (
            <ImageContainer>
              <img src={data.picture.file.url} alt="download info" />
            </ImageContainer>
          )}
          {/* 
          <TextAndIcon>
            <Icon>
              <img src={StackIcon} alt="stack" />
            </Icon>
            <div>
              <TextTitle>Lorem ipsum</TextTitle>
              <Text>
                Etiam sed vulputate nisl, eu elementum arcu. Vivamus dignissim
                tortor in tellus dictum pellentesque.{' '}
              </Text>
            </div>
          </TextAndIcon>
          <TextAndIcon>
            <Icon>
              <img src={CpuIcon} alt="cpu" />
            </Icon>
            <div>
              <TextTitle>Lorem ipsum</TextTitle>
              <Text>
                Etiam sed vulputate nisl, eu elementum arcu. Vivamus dignissim
                tortor in tellus dictum pellentesque.{' '}
              </Text>
            </div>
          </TextAndIcon>
          <TextAndIcon>
            <Icon>
              <img src={LightBulbIcon} alt="light bulb" />
            </Icon>
            <div>
              <TextTitle>Lorem ipsum</TextTitle>
              <Text>
                Etiam sed vulputate nisl, eu elementum arcu. Vivamus dignissim
                tortor in tellus dictum pellentesque.{' '}
              </Text>
            </div>
          </TextAndIcon>
          <TextAndIconMobile>
            <TextIconContainer>
              <Icon>
                <img src={StackIcon} alt="stack" />
              </Icon>
              <TextTitle>Lorem ipsum</TextTitle>
            </TextIconContainer>
            <Text>
              Etiam sed vulputate nisl, eu elementum arcu. Vivamus dignissim
              tortor in tellus dictum pellentesque.{' '}
            </Text>
          </TextAndIconMobile>
          <TextAndIconMobile>
            <TextIconContainer>
              <Icon>
                <img src={CpuIcon} alt="cpu" />
              </Icon>
              <TextTitle>Lorem ipsum</TextTitle>
            </TextIconContainer>
            <Text>
              Etiam sed vulputate nisl, eu elementum arcu. Vivamus dignissim
              tortor in tellus dictum pellentesque.{' '}
            </Text>
          </TextAndIconMobile>
          <TextAndIconMobile>
            <TextIconContainer>
              <Icon>
                <img src={LightBulbIcon} alt="light bulb" />
              </Icon>
              <TextTitle>Lorem ipsum</TextTitle>
            </TextIconContainer>
            <Text>
              Etiam sed vulputate nisl, eu elementum arcu. Vivamus dignissim
              tortor in tellus dictum pellentesque.{' '}
            </Text>
          </TextAndIconMobile> */}
        </Column>
        <Column>
          <BackgroundContainer>
            <img src={BG} alt="background" />
          </BackgroundContainer>
          <FormWrapper>
            <Form>
              {success ? (
                <SuccessMessageWrapper
                  title={data.downloadItem.title}
                  link={data.downloadItem.file.url}
                  name={successName}
                />
              ) : (
                <>
                  <img src={HiglightIcon} alt="highlight" />
                  <FormTitle>Free download</FormTitle>
                  <FormInfo>
                    Enter your name and email for immediate access. No waiting,
                    no spam, promise!
                  </FormInfo>
                  <FormInputs>
                    <TwoInputs>
                      <div>
                        <FormInput
                          type="text"
                          name="firstname"
                          placeholder="First name*"
                          onChange={handleChange}
                          onFocus={() => setFirstNameError('')}
                          style={{
                            borderTopRightRadius: '0',
                            borderBottomRightRadius: '0',
                          }}
                          error={firstNameError}
                        />
                        {firstNameError && (
                          <InlineError>{firstNameError}</InlineError>
                        )}
                      </div>
                      <div>
                        <FormInput
                          type="text"
                          name="lastname"
                          placeholder="Last name*"
                          onChange={handleChange}
                          onFocus={() => setLastNameError('')}
                          style={{
                            borderTopLeftRadius: '0',
                            borderBottomLeftRadius: '0',
                          }}
                          error={lastNameError}
                        />
                        {lastNameError && (
                          <InlineError>{lastNameError}</InlineError>
                        )}
                      </div>
                    </TwoInputs>
                    <FormInput
                      type="email"
                      name="email"
                      placeholder="E-mail*"
                      onChange={handleChange}
                      onFocus={() => setEmailError('')}
                      error={emailError}
                    />
                    {emailError && <InlineError>{emailError}</InlineError>}
                  </FormInputs>
                  <FormButton onClick={onSubmit}>
                    Get Free Content
                    <span>
                      {/* <img src={ArrowIcon} alt="arrow" /> */}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M23.0607 13.0607C23.6464 12.4749 23.6464 11.5251 23.0607 10.9393L13.5147 1.3934C12.9289 0.807611 11.9792 0.807611 11.3934 1.3934C10.8076 1.97919 10.8076 2.92893 11.3934 3.51472L19.8787 12L11.3934 20.4853C10.8076 21.0711 10.8076 22.0208 11.3934 22.6066C11.9792 23.1924 12.9289 23.1924 13.5147 22.6066L23.0607 13.0607ZM0 13.5H22V10.5H0V13.5Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                  </FormButton>
                  <FormCheckboxes>
                    <Checkbox
                      isLink
                      checked={inputOneChecked}
                      onChange={(e) =>
                        handleCheckboxChange(e, setInputOneChecked)
                      }
                      isError={checkboxError}
                    />
                    <Checkbox
                      // label={data.checkboxTwo}
                      checked={inputTwoChecked}
                      label="I agree to receive communications from Innential"
                      onChange={(e) =>
                        handleCheckboxChange(e, setInputTwoChecked)
                      }
                      isError={checkboxError}
                    />
                    {checkboxError && (!inputOneChecked || !inputTwoChecked) && (
                      <InlineError
                        style={{
                          textAlign: 'left',
                          left: '-11px',
                          bottom: '-13px',
                        }}
                      >
                        <span style={{ color: '#fb5854' }}>* </span>
                        {checkboxError}
                      </InlineError>
                    )}
                  </FormCheckboxes>
                </>
              )}
            </Form>
          </FormWrapper>
        </Column>
      </Hero>
    </Wrapper>
  )
}

export default Download
